import * as React from "react"
import Contact from "../components/contact/contact"
import Hero from "../components/hero/hero"
import TextRight from "../components/image-left-text-right"
import IndexService from "../components/index-service"
import Layout from "../components/layout"
import logo from "../components/logo.png"
import Seo from "../components/seo"
import WorkSlider from "../components/slider/slider"
import TextLeft from "../components/text-left"
import about from "../images/drone.jpeg"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero
      logo={logo}
      title="Parkside Building Contractors"
      subTitle="Leading the way in a wide range of building services from concept to completion. We specialise in new builds, restoration work and commerical fitouts"
    />

    <TextRight
      title="About us"
      textOne="We are an established business, priding ourselves on taking our clients projects from concept to completion. "
      textTwo="We specialise in a wide range of building services from New Builds to extensions, restoration projects and commerical fit outs."
      button="FIND OUT MORE"
      link="/about"
      image={about}
    />
    <IndexService link="/services" />

    <WorkSlider />

    <TextLeft
      title="From concept to completion"
      text="Take a look at some of our most recent work. We pride outselves on providing the best possible service and outcome from start to finish, bringing your ideas to life"
      buttonText="GET A FREE QUOTE"
      link="#contact"
    />

    <Contact />
  </Layout>
)

export default IndexPage

import * as React from "react"
// import { Link } from "gatsby"

const IndexService = props => (
  <div className="service-bg md:pt-28 pb-20">
    <div className="grid grid-cols-1 md:grid-cols-2 justify-center md:m-auto md:max-w-6xl md:mt-30 pt-14 px-7">
      <div className=" max-w-md mb-10">
        <h2 className="text-4xl md:text-5xl font-normal tracking-wide leading-80">
          Services we offer
        </h2>

        <p className="text-base tracking-wide">
          We cover a wide range of building services across Yorkshire. Get in
          touch with us for a free quote or for any questions you may have. No
          project is too big or too small for our skilled team.
        </p>

        <a
          href={props.link}
          className=" text-green-500 border border-green-500 p-2 mr-4 mt-8"
        >
          FIND OUT MORE
        </a>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <svg
            className=" mb-5"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="32" fill="#25A09C" />
            <path
              d="M39 32H40.7C41.16 32 41.38 31.43 41.03 31.13L32.67 23.6C32.29 23.26 31.71 23.26 31.33 23.6L22.97 31.13C22.63 31.43 22.84 32 23.3 32H25V35H24C23.45 35 23 35.45 23 36C23 36.55 23.45 37 24 37H25V39C25 39.55 25.45 40 26 40C26.55 40 27 39.55 27 39V37H31V39C31 39.55 31.45 40 32 40C32.55 40 33 39.55 33 39V37H37V39C37 39.55 37.45 40 38 40C38.55 40 39 39.55 39 39V37H40C40.55 37 41 36.55 41 36C41 35.45 40.55 35 40 35H39V32ZM27 35V30.19L31 26.59V35H27ZM33 35V26.59L37 30.19V35H33Z"
              fill="white"
            />
          </svg>

          <h4 className=" text-2xl font-normal">Extensions</h4>
          <p>
            Extensions are becoming more popular as familes grow larger and
            people settle in their current homes. Get in touch to see how we can
            help!
          </p>
        </div>

        <div>
          <svg
            className=" mb-5"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="32" fill="#25A09C" />
            <path
              d="M39 32H40.7C41.16 32 41.38 31.43 41.03 31.13L32.67 23.6C32.29 23.26 31.71 23.26 31.33 23.6L22.97 31.13C22.63 31.43 22.84 32 23.3 32H25V35H24C23.45 35 23 35.45 23 36C23 36.55 23.45 37 24 37H25V39C25 39.55 25.45 40 26 40C26.55 40 27 39.55 27 39V37H31V39C31 39.55 31.45 40 32 40C32.55 40 33 39.55 33 39V37H37V39C37 39.55 37.45 40 38 40C38.55 40 39 39.55 39 39V37H40C40.55 37 41 36.55 41 36C41 35.45 40.55 35 40 35H39V32ZM27 35V30.19L31 26.59V35H27ZM33 35V26.59L37 30.19V35H33Z"
              fill="white"
            />
          </svg>

          <h4 className=" text-2xl font-normal">New Build Houses</h4>
          <p>
            From concept to completion we are able to take undertake any new
            build projects - find out more!
          </p>
        </div>

        <div>
          <svg
            className=" mb-5"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="32" fill="#25A09C" />
            <path
              d="M39 32H40.7C41.16 32 41.38 31.43 41.03 31.13L32.67 23.6C32.29 23.26 31.71 23.26 31.33 23.6L22.97 31.13C22.63 31.43 22.84 32 23.3 32H25V35H24C23.45 35 23 35.45 23 36C23 36.55 23.45 37 24 37H25V39C25 39.55 25.45 40 26 40C26.55 40 27 39.55 27 39V37H31V39C31 39.55 31.45 40 32 40C32.55 40 33 39.55 33 39V37H37V39C37 39.55 37.45 40 38 40C38.55 40 39 39.55 39 39V37H40C40.55 37 41 36.55 41 36C41 35.45 40.55 35 40 35H39V32ZM27 35V30.19L31 26.59V35H27ZM33 35V26.59L37 30.19V35H33Z"
              fill="white"
            />
          </svg>

          <h4 className=" text-2xl font-normal">Loft Conversions</h4>
          <p>
            Creating more space within your home can be a great alternative to
            moving. Get in touch to see how we could help.
          </p>
        </div>

        <div>
          <svg
            className=" mb-5"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="32" cy="32" r="32" fill="#25A09C" />
            <path
              d="M39 32H40.7C41.16 32 41.38 31.43 41.03 31.13L32.67 23.6C32.29 23.26 31.71 23.26 31.33 23.6L22.97 31.13C22.63 31.43 22.84 32 23.3 32H25V35H24C23.45 35 23 35.45 23 36C23 36.55 23.45 37 24 37H25V39C25 39.55 25.45 40 26 40C26.55 40 27 39.55 27 39V37H31V39C31 39.55 31.45 40 32 40C32.55 40 33 39.55 33 39V37H37V39C37 39.55 37.45 40 38 40C38.55 40 39 39.55 39 39V37H40C40.55 37 41 36.55 41 36C41 35.45 40.55 35 40 35H39V32ZM27 35V30.19L31 26.59V35H27ZM33 35V26.59L37 30.19V35H33Z"
              fill="white"
            />
          </svg>

          <h4 className=" text-2xl font-normal">Commercial Fit Outs</h4>
          <p>
            Commercial fit outs are one of our specialites. From bars and
            resturants to bespoke builds, get in touch.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default IndexService

import React, { Component } from "react"
import Slider from "react-slick"
// import newBuild from "../../images/newbuild.png"
import port11 from "../../images/drone.jpeg"
import port1 from "../../images/port-1.jpeg"
import port10 from "../../images/port-10.jpeg"
import port2 from "../../images/port-2.jpeg"
import port3 from "../../images/port-3.jpeg"
import port4 from "../../images/port-4.jpeg"
import port5 from "../../images/port-5.jpeg"
import port6 from "../../images/port-6.jpeg"
import port7 from "../../images/port-7.jpeg"
import port8 from "../../images/port-8.jpeg"
import port9 from "../../images/port-9.jpeg"

export default class WorkSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <div className="">
        <Slider {...settings}>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port11} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port10} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port9} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port8} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port7} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port6} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port5} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port4} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port3} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port2} alt="newbuild" />
          </div>
          <div className="relative flex justify-center m-auto items-center">
            <div className="bg-white px-16 py-10 w-1/3 absolute bottom-0 left-0"></div>
            <img className="md:h-[80vh]" src={port1} alt="newbuild" />
          </div>
        </Slider>
      </div>
    )
  }
}

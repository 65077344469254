import * as React from "react"
// import { Link } from "gatsby"

const TextLeft = props => (
  <div className="">
    <div className="grid md:grid-cols-2 justify-center m-auto md:max-w-6xl mt-10 mb-20 px-6">
      <div className=" max-w-md">
        <h2 className="text-4xl md:text-5xl font-normal tracking-wide leading-80">
          {props.title}
        </h2>

        <p className="text-base tracking-wide">{props.text}</p>

        <a
          href={props.link}
          className=" text-green-500 border border-green-500 p-2 mr-4 mt-8"
        >
          {props.buttonText}
        </a>
      </div>

      <div className="grid grid-cols-2 gap-3"></div>
    </div>
  </div>
)

export default TextLeft
